<template>
  <div class="record">
    <div class="user-tit">考试记录</div>
    <div class="record-list" v-if="dataList.length && !loading">
      <div class="record-list-item" v-for="item in dataList" :key="item.id">
        <div class="record-list-item-score">
          <div>{{ item.score }}分</div>
          <div class="record-list-item-tag green" v-if="item.status == 1">考试中</div>
          <div class="record-list-item-tag" :class="item.status == 2 && item.is_pass == 1 ? 'green':''" v-else>{{ item.status == 2 && item.is_pass == 1 ? '已通过' : '未通过' }}</div>
        </div>
        <div class="record-list-item-name">{{item.train && item.train.generate_category.name}}</div>
        <div class="record-list-item-desc">考试时间：{{item.timelong}}分钟 </div>
        <div class="record-list-item-desc">通过条件：{{item.train && item.train.pass_score}}分（满分{{item.train && item.train.total_score}}）</div>
<!--        <div class="record-list-item-btn" @click="goInfo(item)">{{item.status == 1 ? '继续考试' : '去查看'}}</div>-->
      </div>
    </div>
    <div class="no-data" v-if="!dataList.length && !loading">
      <img src="../../assets/images/common/no-data.png" width="223" height="129" />
      <p>还没有考试记录哦~</p>
    </div>
    <div style="height: 300px;position: relative" v-loading="loading" v-if="loading"></div>
  </div>
</template>

<script>
export default {
  name: "certificate",
  data(){
    return {
      tabList:[
        {
          name:'全部',
          id:1,
        },
        {
          name:'已失效',
          id:2,
        },
      ],
      curTabIndex:0,
      dataList:[],
      loading:true,
    }
  },
  created(){
    this.getRecordList();
  },
  methods:{
    changeTab(index){
      this.curTabIndex = index;
    },
    getRecordList(){
      this.loading = true;
      let params = {
        action:'myTrainList',
        // page:this.recordPage,
        // pageSize:this.recordPageSize,
        type:1
      };
      this.api.attestation.trainExecute(params).then((res)=>{

        this.loading = false;

        this.dataList = res.list;
        // this.recordTotal = Number(res.count);
        // this.trainStatuses = res.statuses;//train.status
        // this.trainUserStatuses = res.trainUserStatuses;//trainuser.status
        // this.generatetypesObj = res.generate_types;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    goInfo(data){
      if(data.status == 2){
        this.$router.push({
          path:'/attestation/examDetail',
          query:{
            id:data.train.generate_category_id,
            trainId:data.train_id,
          }
        })
      }else if(data.status == 1){
        this.$router.push({
          path:'/attestation/exam',
          query:{
            id:data.train.generate_category_id,
          }
        })
      }

    }
  }
}
</script>

<style scoped lang="scss">
.record{
  .user-tit{
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: bold;
  }
  .record-list{
    width: 100%;
    @include flex(row,flex-start,flex-start);
    flex-wrap: wrap;
    .record-list-item{
      margin-right: 20px;
      margin-bottom: 30px;
      padding: 20px;
      width: 230px;
      background: #FFFFFF;
      box-shadow: 0px 0px 20px 0px rgba(0,35,136,0.08);
      border-radius: 10px;

      .record-list-item-score{
        @include flex(row,flex-start,center);
        font-size: 20px;
        font-weight: bold;
        color: $theme-blue;

        .record-list-item-tag{
          margin-left: 20px;
          @include btn(56px,24px,4px,12px,#FC605C)
        }
      }

      .record-list-item-name{
        margin-top: 14px;
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: bold;
      }
      .record-list-item-desc{
        margin-bottom: 8px;
        font-size: 12px;
        color: #666666;
      }
      .record-list-item-btn{
        margin-top: 24px;
        @include btn(120px,38px,19px,14px,linear-gradient(222deg, #3A6CFF, #004FEB));
      }
    }
    .certificate-list-item:nth-child(4n){
      margin-right: 0;
    }
  }
}
</style>
